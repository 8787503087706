import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/SEO"

const IndexPage = () => (
  <Layout>
    <SEO title="Welcome" />
    <h1>Mr & Mrs Roommate</h1>
    <h2>We are just two people trying to become better humans</h2>
    <div className="grid__two">
      <div className="grid__two_single">
        <p>
          Doing projects and trying new things. That is what we do.
          <br />
          We have many adventures and things we love. So we are showing you how
          we live. <br /> Follow us on our journeys of{" "}
          <ul>
            <li>Improving Health</li>
            <li>Music Adventures</li>
            <li>Blogs</li>
          </ul>
        </p>
        <p>We try not to take ourselves serious.</p>
      </div>
      <div className="grid__two_single">
        <div style={{ maxWidth: `600px` }}>
          <Image />
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
